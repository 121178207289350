import { StatusCodes } from 'http-status-codes'
import _ from 'lodash'
import { ErrorKey, SuccessKey } from './api'
import type { AxiosResponse } from 'axios'

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g

type UserFacingMessage = Record<string, string>

export const userFacingMessage: UserFacingMessage = {
  [ErrorKey.ADD_NOTE]: 'Unable to add note',
  [ErrorKey.COMPLETE_APPLICANTS_NEEDS_APPLICATION_MAILED_ERROR]:
    'Unable to complete applicants needs application mailed tasks',
  [ErrorKey.CREATE_NEW_CASE_ERROR]: 'Unable to create case',
  [ErrorKey.DELETE_APPLICANT_ERROR]: 'Unable to delete applicant. Please try again!',
  [ErrorKey.DELETE_APPLICANT_HAS_CASES]: 'Please delete any related cases prior to deleting the applicant record.',
  [ErrorKey.DOWNLOAD_IN_PROGRESS]: 'Your file will be downloaded shortly',
  [ErrorKey.FETCH_APPOINTMENT_SLOTS]: 'Unable to fetch appointment slots.',
  [ErrorKey.FETCH_FORM_CATALOG_IDS_ERROR]: 'Unable to fetch form catalog ids',
  [ErrorKey.FETCH_MASTER_CATALOG_RESULTS_ERROR]: 'Unable to fetch master catalog results',
  [ErrorKey.FETCH_MOBILITY_AIDS_FOR_ORGANIZATION]:
    'Unable to fetch mobility aids for organization. This may cause issues with the appointment scheduling process.',
  [ErrorKey.GENERAL_ERROR]: 'Something went wrong. Please try again later.',
  [ErrorKey.GENERAL_SAVE_ERROR]: 'Unable to save form',
  [ErrorKey.GENERATE_ELIGIBILITY_LETTER]: 'Unable to generate eligibility letter',
  [ErrorKey.GET_APPLICANT_RECORDS]: 'Unable to get applicant records',
  [ErrorKey.GET_APPLICANT]: 'Unable to get applicant - No result found for applicant id {{applicantId}}',
  [ErrorKey.GET_APPLICANT_HISTORY]: 'Unable to get applicant history.',
  [ErrorKey.GET_APPLICATION_SCREEN]:
    'Unable to get application screen config - No result found for application screen id {{applicationScreenId}}',
  [ErrorKey.GET_CASE]: 'Unable to get case - No result found for case id {{caseId}}',
  [ErrorKey.GET_EMPLOYEES]: 'Unable to get employees',
  [ErrorKey.GET_FORM_DETAILS]: 'Unable to get form details',
  [ErrorKey.GET_INTAKE_FORM_DATA]: 'Unable to get intake form data for applicant.',
  [ErrorKey.GET_NOTES]: 'Unable to get notes for the {{reference}}',
  [ErrorKey.GET_ORGANIZATION_CASE_TYPES]: 'Unable to get organization case types',
  [ErrorKey.GET_ORGANIZATION_INFO]: 'Unable to get organization info',
  [ErrorKey.MY_DASHBOARD_VIEWS]: 'Unable to get my dashboard views',
  [ErrorKey.OUTREACH_CREATE_ERROR]:
    'We are unable to create partner at this time. You can try submitting the form again.',
  [ErrorKey.OUTREACH_DELETE_ERROR]: 'Oops! We encountered an error while deleting partner. Please try again!',
  [ErrorKey.OUTREACH_EVENT_CREATE_ERROR]:
    'Oh no! We encountered an error while creating partner event. Please try again!',
  [ErrorKey.OUTREACH_EVENT_DELETE_ERROR]: 'Encountered an error while deleting partner event. Please try again!',
  [ErrorKey.OUTREACH_EVENT_DUPLICATE_ERROR]: 'An outreach event with the given name already exists!',
  [ErrorKey.OUTREACH_EVENT_GET_ERROR]: 'We are unable to get partner event details at this time. Please try again!',
  [ErrorKey.OUTREACH_EVENT_RECORDS_GET_ERROR]: 'We are unable to get partner events at this time. Please try again!',
  [ErrorKey.OUTREACH_EVENT_UPDATE_ERROR]:
    'Oops! We encountered an error while updating partner event. Please try again!',
  [ErrorKey.OUTREACH_GET_ERROR]: 'Sorry, we are unable to get partner details at this time. Please try again!',
  [ErrorKey.OUTREACH_ORGANIZATION_DUPLICATE_ERROR]: 'An outreach organization with the given name already exists!',
  [ErrorKey.OUTREACH_UPDATE_ERROR]: 'Ouch! We encountered an error while updating partner details. Please try again!',
  [ErrorKey.PDF_DOCUMENT]: 'Error displaying PDF document',
  [ErrorKey.RECAPTCHA]: 'Unable to execute reCAPTCHA',
  [ErrorKey.SAVE_CASE_ERROR]: 'Unable to save case',
  [ErrorKey.SAVE_FORM_DATA_ERROR]: 'Unable to save form data',
  [ErrorKey.SEARCH_ERROR]: 'Unable to get results for search criteria',
  [ErrorKey.SUBMIT_INTAKE_FORM_ERROR]: 'Unable to submit intake form.',
  [ErrorKey.UPDATE_NOTE]: 'Unable to update note',
  [ErrorKey.UPLOAD_FILE]: 'Unable to upload file',
  [SuccessKey.CREATE_APPLICANT_SUCCESS]: 'Contact created successfully!',
  [SuccessKey.DELETE_APPLICANT_SUCCESS]: 'Successfully deleted applicant',
  [SuccessKey.GENERAL_SAVE_SUCCESS]: 'Successfully Saved',
  [SuccessKey.OUTREACH_CREATE_SUCCESS]: 'Great! Partner created successfully.',
  [SuccessKey.OUTREACH_DELETE_SUCCESS]: 'We have successfully deleted the partner.',
  [SuccessKey.OUTREACH_EVENT_CREATE_SUCCESS]: 'Partner event created successfully.',
  [SuccessKey.OUTREACH_EVENT_DELETE_SUCCESS]: 'We have successfully deleted the partner event.',
  [SuccessKey.OUTREACH_EVENT_UPDATE_SUCCESS]: 'Partner event updated successfully.',
  [SuccessKey.OUTREACH_UPDATE_SUCCESS]: 'Great! Partner updated successfully.',
  [SuccessKey.SAVE_APPLICANT_SUCCESS]: 'Contact updated successfully!',
  [SuccessKey.SAVE_CASE_SUCCESS]: 'Case updated successfully!',
  [SuccessKey.SAVE_FORM_DATA_SUCCESS]: 'Successfully saved form data.',
  [SuccessKey.SUBMIT_INTAKE_FORM_SUCCESS]: 'Successfully submitted intake form.',
  ADD_APPOINTMENT_CTA: 'Add Appointment',
  ADD_CALL_NOTE_TASK_CTA_LABEL: 'Add Note',
  ADDITIONAL_FORMS_LABEL: 'Additional Forms',
  APPLICANT_CATALOG_ID_ERROR_DESCRIPTION: 'Error reading the applicant catalog ID value. Please try again.',
  APPLICANT_CATALOG_ID_ERROR_HEADING: 'Missing Applicant Catalog ID',
  APPLICANT_DELETE_CONFIRMATION: 'Are you sure want to delete the applicant?',
  APPLICANT_HISTORY_LABEL: 'Applicant History',
  APPLICANT_PORTAL_MTS_HEADING: 'HOW TO APPLY',
  APPLICATION_COMPLETE: 'Application Complete!',
  APPLICATION_SUBMIT_ERROR: 'An error occurred and the application was not submitted.',
  APPLICATION_SUBMIT_SUCCESS: 'Application submitted successfully.',
  APPOINTMENT_APPLICANT_TYPE_ERROR: 'Please select an applicant type.',
  APPOINTMENT_APPLICANT_TYPE_LABEL: 'Applicant Space Type',
  APPOINTMENT_APPOINTMENT_TYPE_LABEL: 'Appointment Type',
  APPOINTMENT_CREATE_ERROR: 'Error creating appointment',
  APPOINTMENT_CREATE_SUCCESS: 'Appointment created successfully',
  APPOINTMENT_DATE_TIME_LABEL: 'Appointment Date & Time',
  APPOINTMENT_DETAILS_LABEL: 'Appointment Details',
  APPOINTMENT_DROP_OFF_ADDRESS_ERROR: 'Please enter a dropoff address.',
  APPOINTMENT_DROP_OFF_ADDRESS_INVALID: 'Please enter a valid dropoff address.',
  APPOINTMENT_DROP_OFF_ADDRESS_LABEL: 'Dropoff Address',
  APPOINTMENT_EDIT_TITLE: 'Appointment Details',
  APPOINTMENT_MAE_ASSIGNED_LABEL: 'MAE Assigned',
  APPOINTMENT_NEW_APPOINTMENT_TITLE: 'Confirm Appointment Time',
  Assessment_Type: 'Assessment Type',
  APPOINTMENT_NOTES_LABEL: 'Appointment Notes',
  APPOINTMENT_PASSENGER_MOBILITY_AIDS_ERROR: 'Please select a passenger mobility aid.',
  APPOINTMENT_PCA_MOBILITY_AIDS_ERROR: 'Please select a PCA mobility aid.',
  APPOINTMENT_PCA_REQUESTED_LABEL: 'PCA Requested',
  APPOINTMENT_PCA_TYPE_ERROR: 'Please select a PCA type.',
  APPOINTMENT_PCA_TYPE_LABEL: 'PCA Space Type',
  APPOINTMENT_PICKUP_ADDRESS_ERROR: 'Please enter a pickup address.',
  APPOINTMENT_PICKUP_ADDRESS_INVALID_ERROR: 'Please enter a valid pickup address.',
  APPOINTMENT_PICKUP_ADDRESS_LABEL: 'Pickup Address',
  APPOINTMENT_RIDE_REQUESTED_LABEL: 'Ride Requested',
  APPOINTMENT_STATUS_LABEL: 'Appointment Status',
  APPOINTMENT_TRIP_TYPE_LABEL: 'Trip Type',
  APPOINTMENT_TYPE_ERROR: 'Please select an appointment type.',
  APPOINTMENT_UPDATE_ERROR: 'Error updating appointment',
  APPOINTMENT_UPDATE_SUCCESS: 'Appointment updated successfully',
  APPOINTMENT_VIEW_BY_LABEL: 'Appointment View By',
  APPOINTMENTS_COUNT_LABEL: 'Appointments ({{count}})',
  APPLICANT_DUPLICATE_ERROR: 'Unable to create applicant - This applicant already exists, please use the',
  APPROVE_CTA: 'Approve',
  ASSIGNEE_LABEL: 'Assignee',
  BACK_CTA: 'Back',
  BODY_LABEL: 'Body',
  CALL_DATE_TIME_LABEL: 'Call Date & Time',
  CALLS_NOTES_TASKS_LABEL: 'Calls, Notes, and Tasks',
  CANCEL_CONFIRMATION: 'Are you sure you want to cancel and lose your changes?',
  CANCEL_CTA: 'Cancel',
  CASES_COUNT_LABEL: 'Cases ({{count}})',
  CLOSE_CASE_CTA: 'Close Case',
  CLOSE_CASE_ERROR: 'Unable to close case',
  CLOSE_CASE_SUCCESS: 'Case closed successfully',
  COMMENTS_LABEL: 'Comments',
  CONFIRM_CTA: 'Confirm',
  COPY_EXTERNAL_LINK: 'Copy External Link',
  COPY_TO_CLIPBOARD_CTA: 'Copied to clipboard',
  CREATE_ACTION_LABEL: 'Create {{action}}',
  CREATE_NEW_CASE_CTA: 'Create New Case',
  CREATE_NEW_CASE_SUCCESS: 'Case created successfully.',
  CREATE_NEW_EVENT_CTA: 'Create New Event',
  CASE_HISTORY_LABEL: 'Case History',
  DASHBOARD_NO_VIEW_SELECTED: 'No view selected. Please select a view, and try exporting again.',
  DELETE_CASE_ERROR: 'Unable to delete case',
  DELETE_CASE_SUCCESS: 'Case deleted successfully',
  DELETE_CONFIRMATION: 'Are you sure you want to delete this?',
  DELETE_CTA: 'Delete',
  DELETE_OUTREACH_PARTNER_CONFIRMATION_1: "Are you sure you want to delete this partner's profile?",
  DELETE_OUTREACH_PARTNER_CONFIRMATION_2: 'Doing so will delete all associated events.',
  DENY_CTA: 'Deny',
  DOCUMENT_TYPE_LABEL: 'Document Type',
  DOCUMENTS_COUNT_LABEL: 'Documents ({{count}})',
  DOWNLOAD_FILE_ERROR: 'Unable to download file',
  DOWNLOAD_FILE_LABEL: 'Download File',
  DUE_DATE_LABEL: 'Due Date',
  EDIT_CTA: 'Edit',
  ELIGIBILITY_APPLICATION: '{{caseType}} Eligibility Application',
  ELIGIBILITY_DECISIONS_COUNT_LABEL: '{{type}} Eligibility Decisions ({{count}})',
  ENTER_TIME_PLACEHOLDER: 'Enter Time',
  EVENT_DATE_LABEL: 'Event Date',
  EVENT_ID_LABEL: 'Event ID',
  EVENT_NAME_LABEL: 'Event Name',
  EVENTS_COUNT_LABEL: 'Events ({{count}})',
  EXTERNAL_SAVE_FORM_DATA_ERROR: 'Unable to save form data. Please reach out for assistance.',
  ED_HISTORY_LABEL: 'Eligibility Determination History',
  EXISTING_APPLICANT: 'existing record',
  FETCH_DOCUMENT_ERROR: 'Unable to fetch document',
  FILE_NAME_LABEL: 'File Name',
  FILE_UPLOAD_SUCCESS: 'File uploaded successfully',
  FOSTER_FARE_ELIGIBILITY_APPLICATION_TITLE: 'PRONTO EXTEND Eligibility Application',
  GENERAL_CONFIRMATION_TEXT:
    'Your confirmation number is <code>{{confirmationNumber}}</code>.<br />Please save this confirmation number as it will be used to complete your Professional Verification.<br />The confirmation number and instructions on how to complete the professional verification has been sent to the email included in your online application.',
  GENERAL_CTA_SUBMIT: 'Submit',
  GENERAL_DATE_LABEL: 'Date',
  GENERAL_ED_LETTER_CAPTION: 'Please save the form before generating letter.',
  GENERAL_END_TIME_LABEL: 'End Time',
  GENERAL_ENTER_VALUE_ERROR: 'Please enter a value',
  GENERAL_INVALID_PHONE_NUMBER_ERROR: 'Please enter a valid phone number',
  GENERAL_INVALID_ZIPCODE_ERROR: 'Please enter valid zip code',
  GENERAL_MULTI_SELECT_PLACEHOLDER: 'Select one or more options',
  GENERAL_ORGANIZATION_ERROR: 'Organization not defined',
  GENERAL_REQUIRED_FIELDS_ERROR: 'Please fill out all required fields',
  GENERAL_REVIEW_ERRORS_ERROR: 'Please review errors on the page and correct them.',
  GENERAL_START_TIME_LABEL: 'Start Time',
  GENERAL_UNABLE_TO_FIND_APPLICANT_ERROR: 'Unable to find applicant with the information provided',
  GENERATE_ED_LETTER_ERROR: 'Unable to generate letter. Please reach out for assistance.',
  GENERATE_ED_LETTER_TEMPLATE_ERROR: 'Please select letter template options',
  GENERATE_ED_LETTER_SUCCESS:
    'The Eligibility Letter request has been submitted, and the letter should attach to the case momentarily.',
  GENERATE_LETTER_SUCCESS:
    'The Letter request has been submitted, and the letter should attach to the case momentarily.',
  GENERATE_ELIGIBILITY_LETTER_CTA: 'Generate Eligibility Letter',
  GENERATE_ELIGIBILITY_LETTER_LANGUAGE_CTA: 'Generate {{language}} Eligibility Letter',
  GENERIC_ERROR_DESCRIPTION_TEXT: 'An unexpected error has occurred. Please try again later.',
  GENERIC_ERROR_HEADING_TEXT: 'Something went wrong.',
  INTAKE_FORM_DUPLICATE_SUBMISSION_TEXT:
    'The intake form has been added to this existing applicant’s profile. View it below',
  MBTA_CONFIRMATION_TEXT:
    'Your confirmation number is <code>{{confirmationNumber}}</code>.<br />Please save this confirmation number for record of your completed application.',
  MTS_ADA_CONFIRMATION_TEXT:
    '<p>Your confirmation number is: <code>{{confirmationNumber}}</code>.</p><p>Please save this confirmation number as it will be used to complete your Professional Verification.</p><p>The confirmation number and instructions on how to complete the professional verification has been sent to the email in your online application.</p><p><b>If no email was included in your online application, you can print the completed application for your records. Please follow-up with your medical professional. Once he/she has completed and submitted the Part B: Health Care Provider Certification, call 844-299-6326 to schedule an in-person assessment.</b></p>',
  MTS_APPLICATION_COMPLETE: 'Your application has been submitted!',
  MTS_FOSTER_FARE_CONFIRMATION_TEXT:
    '<p>MTS will review your application and supporting document(s). You will receive a letter with your eligibility determination within 15 business days.</p>',
  NETWORK_ISSUE_MESSAGE: 'Please try again in a few minutes when you have network connectivity',
  NOTE_DETAILS_LABEL: '{{noteType}} Details',
  NOTE_TITLE_REQUIRED_LABEL: 'Please enter subject for the note',
  NOTES_RECORD_TYPE_DESCRIPTION: 'Please select a record type to create.',
  NOTES_RECORD_TYPE_LABEL: 'Record Type',
  OK_CTA: 'Ok',
  ORG_ATTRIBUTE_ERROR: 'Unable to fetch Organization Attributes',
  OPTIONAL_LABEL: '(Optional)',
  PAGE_NOT_FOUND_DESCRIPTION: 'Sorry, the page you are looking for does not exist. Please check the URL and try again.',
  PAGE_NOT_FOUND_HEADING: 'Page not found',
  PARTNER_DETAILS_HEADING: 'Partner Details',
  PARTNER_DETAILS_NAME_LABEL: '{{partnerName}} Details',
  PARTNER_EMPTY_DETAILS: 'No details available for this partner',
  PARTNER_LABEL: 'Partner',
  PARTNER_NAME_LABEL: 'Partner Name',
  PARTNER_RECORDS_LABEL: 'Partner Records',
  PASSENGER_MOBILITY_AIDS_LABEL: 'Passenger Mobility Aids',
  PCA_MOBILITY_AIDS_LABEL: 'PCA Mobility Aids',
  PICKUP_TIME_LABEL: 'Pickup Time',
  PREVIEW_NOT_AVAILABLE: 'Preview for this file is not available, Please download to view.',
  PRINT_BLANK_CERTIFICATION_CTA_LABEL: 'Print Blank Certification',
  PRINT_COMPLETED_APPLICATION_CTA_LABEL: 'Print Completed Application',
  PROFESSIONAL_VERIFICATION_APPLICANT_DOB_LABEL: 'Applicant Date of Birth',
  PROFESSIONAL_VERIFICATION_APPLICANT_DOB_PLACEHOLDER: 'Enter Applicant Date of Birth',
  PROFESSIONAL_VERIFICATION_APPLICANT_NAME_LABEL: 'Applicant Last Name',
  PROFESSIONAL_VERIFICATION_APPLICANT_NAME_PLACEHOLDER: 'Enter Applicant Last Name',
  PROFESSIONAL_VERIFICATION_CONFIRMATION_NUMBER_LABEL: 'Confirmation Number',
  PROFESSIONAL_VERIFICATION_CONFIRMATION_NUMBER_PLACEHOLDER: 'Enter Confirmation Number',
  PROFESSIONAL_VERIFICATION_HEADING: 'Please complete the below information from the submitted online application.',
  PROFESSIONAL_VERIFICATION_SUBMIT_ERROR: 'An error occurred and the application was not submitted',
  PROFESSIONAL_VERIFICATION_SUBMIT_SUCCESS: 'Professional verification submitted successfully',
  PROFESSIONAL_VERIFICATION_TITLE: 'Professional Verification',
  RECAPTCHA_SITEKEY_ERROR: 'reCAPTCHA sitekey not defined',
  RIDER_CONFIRMATION_TEXT: "This action will replace already entered data. Are you sure you'd like to proceed?",
  SAVE_CTA: 'Save',
  SCAN_DOCUMENT_LABEL: 'Scan Document',
  SELECT_AGENCY_LABEL: 'Select an agency',
  SELECT_CASE_TYPE_LABEL: 'Please select a case type',
  SELECT_DOCUMENT_TYPE_LABEL: 'Select Document Type',
  SELECT_FILES_LABEL: 'Select Files',
  SEND_TO_RIDER360_CTA: 'Send to Rider360',
  STATUS_LABEL: 'Status',
  SUBJECT_LABEL: 'Subject',
  SUPPLEMENTAL_FORMS_LABEL: 'Supplemental Forms',
  TASK_TYPE_LABEL: 'Task Type',
  TRANSLATE_LANGUAGES_ERROR: 'Unable to get translate languages',
  TRANSPORTATION_NOTES_LABEL: 'Transportation Notes',
  TRIGGER_RIDER360_EXPORT_ERROR: 'Unable to trigger Rider360 export',
  TRIGGER_RIDER360_EXPORT_SUCCESS: 'Rider360 export triggered successfully',
  TYPE_LABEL: 'Type',
  UNEXPECTED_ERROR_DESCRIPTION: 'An unexpected error has occurred. Please try again later.',
  UNEXPECTED_ERROR_HEADING: 'Unexpected Error',
  UPLOAD_DROP_FILES_LABEL: 'Drop files here',
  UPLOAD_FILE_DELETE_FAILED: 'Failed to delete the file',
  UPLOAD_FILE_DELETE_SUCCESS: 'Successfully deleted the file',
  UPLOAD_FILE_FAILED: 'Failed to upload the file',
  UPLOAD_FILE_INSTRUCTION: 'Maximum upload file size: {{maxFileSize}}MB. Pdf, jpg/jpeg, and png files only.',
  UPLOAD_FILE_INVALID_TYPE_ERROR:
    'This file type is not supported, please ensure the file is PDF, JPG/JPEG/PNG, CSV,or DOC.',
  UPLOAD_FILE_SIZE_ERROR: 'File size too large. Please ensure file is less than {{maxFileSize}}MB',
  UPLOAD_FILE_SUCCESS: 'Successfully uploaded the file',
  UPLOAD_FILES_LABEL: 'Upload Files',
  UPLOAD_FILES_MAX_LIMIT_ERROR: 'Please select no more than {{maxFiles}} files',
  UPLOAD_LABEL: 'Upload',
  URL_INCORRECT_LABEL: 'Incorrect URL',
  URL_MISSING_ORG_CODE_ERROR: 'Organization code is not provided. Please check the URL and try again.',
  USER_NOT_AUTHORIZED_ERROR_DESCRIPTION: 'You are not authorized to access this page.',
  USER_NOT_AUTHORIZED_ERROR_HEADING: 'Authorization Error',
  VIEW_INTAKE_FORM_CTA: 'View Intake Form',
  VIEW_OUTREACH_FORM_CTA: 'View outreach Form',
  VIEW_PDF_LABEL: 'View PDF',
  VIEW_HISTORY_CTA: 'View History',
}

/**
 * Helper function to get user facing message
 * @param target - key of userFacingMessage
 * @param params - records to replace the placeholders in user facing message
 * @returns string - user facing message
 */
export const getString = (
  target: keyof typeof userFacingMessage,
  params: Record<string, string | number> = {}
): string => {
  const message = userFacingMessage[target]
  if (!message) {
    return ''
  }
  return _.template(message)(params)
}

/**
 * Helper function to get user facing messages
 * @param targets - array of keys of userFacingMessage
 * @returns string[] - array of user facing messages
 */
export const getStrings = (targets: (keyof typeof userFacingMessage)[]) => {
  const translations = {}
  targets.forEach((key) => {
    Object.assign(translations, { [key]: getString(key) })
  })
  return translations as Record<keyof typeof userFacingMessage, string>
}

// Error handlers
const buildErrorMessage = (error: any): { statusCode: StatusCodes; message: string } => {
  if (!error.response) {
    return {
      statusCode: StatusCodes.INTERNAL_SERVER_ERROR,
      message: userFacingMessage[ErrorKey.GENERAL_ERROR] || '',
    }
  } else if (Array.isArray(error.response.data)) {
    return { statusCode: error.response.status, message: error.response.data[0].message }
  } else if (typeof error.response.data === 'object') {
    return { statusCode: error.response.status, message: error.response.data.message }
  } else {
    return { statusCode: error.response.status, message: error.response.data }
  }
}

const getErrorMessage = (key: ErrorKey, params: { [key: string]: string | number }, err: any) => {
  const error = userFacingMessage[key]
  if (!error) {
    return buildErrorMessage(err).message
  }
  return _.template(error)(params)
}

export const getError = (key: ErrorKey, params: { [key: string]: string | number }, error: any) => ({
  statusCode: (error as any)?.response?.status || StatusCodes.BAD_REQUEST,
  body: getErrorMessage(key, params, error),
})

// Success handlers
const getSuccessMessage = (key: SuccessKey, params: { [key: string]: string | number }, response: any) => {
  const message = userFacingMessage[key]
  if (!message) {
    return response.data.message || userFacingMessage[SuccessKey.GENERAL_SAVE_SUCCESS]
  }
  return _.template(message)(params)
}

export const getSuccess = (key: SuccessKey, params: { [key: string]: string | number }, response: AxiosResponse) => ({
  statusCode: response.status || StatusCodes.OK,
  body: getSuccessMessage(key, params, response),
})
